@import "@vmf/components/src/assets/style/_index.scss";



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.goods-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .image-list-wrap {
        flex: 0 0 auto;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .badge {
            display: inline-block;
            border-radius: 100em;
            background-color: $color-danger;
            color: #fff;
            line-height: 24px;
            min-width: 24px;
            text-align: center;
        }
    }
}
