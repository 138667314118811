@import "@vmf/components/src/assets/style/_index.scss";
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.badge {
    display: inline-block;
    border-radius: 100em;
    background-color: $color-danger;
    color: #fff;
    line-height: 24px;
    min-width: 24px;
    text-align: center;
}
